import React from 'react'

import Link from 'next/link'

import { getRouteById } from 'config/router'
import { useRouterLink } from 'hooks/router'

export const RouteLink = props => {
  const {
    route,
    routeId,
    params,
    className,
    children,
    action = null,
    productId = null
  } = props

  const target = React.useMemo(() => route || getRouteById(routeId), [route, routeId])
  const linkProps = useRouterLink(target, params, true, false, productId)
  return (
    <Link {...linkProps}>
      {action
        ? <a onClick={action} className={className}> {children}</a>
        : <a className={className}> {children} </a>}
    </Link>
  )
}

export default RouteLink
