import PropTypes from 'prop-types'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useContextualRouting } from 'next-use-contextual-routing'

import Url from 'url-parse'

import { matchRouteArray, matchRoute, getPathFromTemplate } from 'utils/routes'

import { useRouter } from 'next/router'

const RouterContext = React.createContext({})

export const RouterProvider = props => {
  const { initialValue, children } = props
  const [activeRoute, setActiveRoute] = useState(
    (initialValue && initialValue.activeRoute) || ''
  )
  const [activeRoot, setActiveRoot] = useState(
    (initialValue && initialValue.activeRoot) || '/'
  )

  const contextValue = {
    activeRoot,
    activeRoute,
    setActiveRoute,
    setActiveRoot
  }

  return (
    <RouterContext.Provider value={contextValue}>
      {children}
    </RouterContext.Provider>
  )
}
RouterProvider.propTypes = {
  children: PropTypes.node
}

/**
 * useRoute React hook
 * matches a route definition or a list of route definitions against the current Next router asPath
 * @param  {string|string[]} routes one or more route definitions
 * @return {object}                 returns an object describing the matching result
 * @property {boolean} isRoot       whether the route definition is a root definition or not
 * @property {boolean} hasRoute     whether the route definition matches the current asPath or not
 * @property {object|boolean} query an object containing the current route parameter values, if the route definition matches the asPath, or false.
 * @property {string} root          the current path root if the route definition matches, or the current asPath otherwise
 */
export const useRoute = (route) => {
  const router = useRouter()
  const { setActiveRoute, setActiveRoot } = useContext(RouterContext)
  const { asPath } = router
  const { isRoot, hasRoute, query, root } = useMemo(
    () => Array.isArray(route)
      ? matchRouteArray(route, asPath)
      : matchRoute(route, asPath),
    [route, asPath]
  )

  useEffect(() => {
    if (isRoot || hasRoute) {
      const activeRoute = asPath.replace(root, '')
      setActiveRoute(asPath.replace(root, ''))
      setActiveRoot(asPath.replace(activeRoute, ''))
    }
  })

  return { hasRoute, root, query, asPath, router }
}

/**
 * useRouterLink React hook
 * generates link props or an handler function to navigate to a specific absolute or relative path based on the current router state
 * @param { object } route    the route config
 * @param { object } params   the route parameter values
 * @param { boolean } asProps whether to return result as function or props
 * @param { boolean } keepQueryParams whether to preserve current query string params or not
 * @return {function|object}  a navigation handler or a next js link prop object
 */
export const useRouterLink = (route, params, asProps, keepQueryParams = false, productId = null) => {
  const { activeRoot } = useContext(RouterContext)
  const router = useRouter()
  const { pathname, asPath } = router
  const { path, linkTemplate } = route
  let newPath = getPathFromTemplate(linkTemplate || path, params)
  const { makeContextualHref } = useContextualRouting()

  const { isRoot, hasRoute } = useMemo(
    () => matchRoute(newPath, asPath),
    [newPath, asPath]
  )

  if (keepQueryParams) {
    const parsedPath = new Url(asPath)
    if (parsedPath.query && parsedPath.query !== '?') {
      newPath += parsedPath.query
    }
  }

  const linkProps = useMemo(() => {
    return (
      productId
        ? {
          href: makeContextualHref({ id: productId }),
          as: `/produit/${productId}/${newPath}`,
          shallow: true
        }
        : {
          href: isRoot && !hasRoute ? path : pathname,
          as: isRoot
            ? newPath
            : !hasRoute
              ? [activeRoot, newPath].join('/').replace('//', '/')
              : asPath,
          shallow: true
        }
    )
  }, [pathname, isRoot, newPath, hasRoute, activeRoot, asPath])

  const handlePush = useCallback(() => {
    router.push(
      linkProps.href,
      linkProps.as,
      { shallow: true }
    )
  }, [linkProps.href, linkProps.as])

  return asProps ? linkProps : handlePush
}
